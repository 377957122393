import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import styled from 'styled-components';

import { contentType } from '../../constant';
import Bullet from './Bullet';

const Main = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  flex-flow: column nowrap;

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &:last-child {
    margin-bottom: 4em;
  }

  .body {
    h3 {
      margin-top: 2em;
    }
  }

  p {
    font-size: 18px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 2em;
    margin-bottom: 2em;
  }
`;

export const Column = styled.div``;

export const Title = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;

const UnOrderListItem = styled(Bullet)`
  margin: 1em 0;
`;

const Row = ({ title, columns }) => (
  <Main>
    {title && <Title>{title}</Title>}

    <div className="body">
      {columns.map(column => {
        switch (column.contentType) {
          case contentType.TEXT:
            return (
              <div amount={columns.length}>
                {column.title && <Title>{column.title}</Title>}
                <ReactMarkdown source={column.text} escapeHtml={false} />
              </div>
            );

          case contentType.BULLETS:
            return (
              <div amount={columns.length}>
                {column.title && <Title>{column.title}</Title>}
                {column.bullets.map(bullet => (
                  <UnOrderListItem
                    key={bullet.title}
                    title={bullet.title}
                    description={bullet.description}
                  />
                ))}
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  </Main>
);

Row.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

Row.defaultProps = {
  title: ''
};

export default Row;
