import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Cover from '../Cover';
import { TitleWithUnderline } from '../Typo';
import { H2 } from '../../shared/MainLayout/Title';

const BlendDefinition = ({ title, description, backgroundImage, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <AbsoluteContainer>
        {title && <H2>{title}</H2>}
        {description && (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </AbsoluteContainer>
      {backgroundImage && <Cover cover={backgroundImage} />}
    </Wrapper>
  );
};

const AbsoluteContainer = styled.div`
  position: absolute;
  left: 3em;
  top: 3em;
  max-width: 70%;
  z-index: 1;

  @media screen and (max-width: 768px) {
    left: 1em;
    top: 1.5em;
    max-width: calc(90% - 1em);
  }
`;

const Description = styled.p`
  font-size: 1.125em;
  font-weight: 400;
  color: #2d3942;
  max-width: 80%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled(TitleWithUnderline)`
  font-size: 1.5em;
  text-align: left;
  font-weight: 600;
  margin-bottom: 2em;
  color: #0b1821;

  &::after {
    left: 0;
    width: 85px;
    bottom: -1em;
    height: 3px;
    background-color: #e41e26;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.25em;
    padding: 0;
    text-align: left;
  }

  @media screen and (max-width: 425px) {
    &::after {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 550px;
  max-height: 250px;
  position: relative;
  overflow: hidden;
`;

BlendDefinition.propTypes = {
  backgroundImage: PropTypes.shape({}),
  description: PropTypes.string,
  title: PropTypes.string
};

BlendDefinition.defaultProps = {
  backgroundImage: null,
  description: null,
  title: null
};

export default BlendDefinition;
